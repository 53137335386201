import React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Colors } from '../utils/constants';

const HoverableLink = ({to, text, className, onClick}) => {
  let link;
  if(to.indexOf('http') !== -1) {
    link = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="hoverable-link"
        href={to}
      >
        <span className="text">{text}</span>
      </a>
    );
  } else {
    link = (
      <Link
        className="hoverable-link"
        to={to}
      >
        <span className="text">{text}</span>
      </Link>
    );
  }
  return (
    <div role="button" tabIndex={0} onKeyDown={onClick} className={className} onClick={onClick}>
      {link}
    </div>
  )
}

export default styled(HoverableLink)`
  width: max-content;
  font-size: 16px;

  a {
    color: ${Colors.EYA_RED};
  }

  ::after {
    content: '';
    display: block;
    width: 0;
    padding: 0;
    height: 5px;
    background: ${Colors.EYA_RED};
    transition: width .3s;
  }

  :hover {
    cursor: pointer;
    color: ${Colors.EYA_RED};
    ::after {
      width: 100%;
    }
  }
`;