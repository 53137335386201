import React from 'react'
import styled from 'styled-components'
function GalleryGrid(props) {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(GalleryGrid)`
display: grid;
grid-gap: 50px 56px;
grid-template-columns: repeat(3, 1fr);
width: 100%;
@media screen and (max-width: 1024px) {
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 768px) {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}

`;