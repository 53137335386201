import React, { useContext } from 'react';
import styled from 'styled-components';
import { TextArea } from './ProjectShowcase';
import { graphql, useStaticQuery } from 'gatsby';
import HoverableLink from '../HoverableLink';
import ArtistsList from '../ArtistsList';
import { shuffle } from '../../utils/helper';
import { ShowcastArtistIDsContext } from '../../context/ArtistsShowcaseContext';
 

const Wrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ArtistsGrid = styled.div`
  flex-grow: 1;
  padding: 50px 0 50px 50px;
  @media screen and (max-width: 768px) {
    padding: 0 50px 0 50px;
  }
`;

const ArtistsShowcase = () => {
  const { artistIds, setArtistIds } = useContext(ShowcastArtistIDsContext);
  const data = useStaticQuery(
    graphql`
      query {
        mdx(frontmatter: {id: {eq: "artists-showcase"}}) {
          frontmatter {
            artistsIDs
          }
        },
        allArtists: allMdx(
          sort: { order: ASC, fields: [frontmatter___id] }
          filter: {fileAbsolutePath: {glob: "**/src/data/artists/*.md"}}
        ) {
          edges {
            node{
              frontmatter {
                id
              }
            }
          }
        }
      }
    `
  );
  const presetIDs = data.mdx.frontmatter.artistsIDs.filter((_, index) => index < 6);
  const allArtistsIDs = data.allArtists.edges.map(e => e.node.frontmatter.id);
  const shuffledIDs = shuffle(allArtistsIDs).filter((a, idx) => idx < 6);
  if(artistIds.length === 0 ) {
    setArtistIds(shuffledIDs)
  }

  return (
    <Wrapper>
      <ArtistsGrid>
        <ShowcastArtistIDsContext.Consumer>
          {({artistIds}) => (
            <ArtistsList artistsList={artistIds.length === 0 ? presetIDs : artistIds}/>
          )}
        </ShowcastArtistIDsContext.Consumer>
      </ArtistsGrid>
      <TextArea>
        <h1>Artists</h1>
        <p>An ever-growing archive of talents</p>
        <HoverableLink to='/artists' text="See more artists"/>
      </TextArea>
    </Wrapper>
  );
};

export default ArtistsShowcase;