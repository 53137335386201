import React from 'react'
import {
  graphql,
  useStaticQuery
} from 'gatsby';
import styled from 'styled-components'
import Img from "gatsby-image";

const LogoImage = ({className}) => {
  const data = useStaticQuery(
    graphql `
      query {
        logo: file(relativePath: { eq: "images/logo-home.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
      }
    `
  );

  return (<Img
    className={className}
    alt='Emerging Young Artists'
    fluid={data.logo.childImageSharp.fluid}
  />)
};

export default styled(LogoImage)`
width: 500px;
@media screen and (max-width: 768px) {
  width: 300px;
}
@media screen and (max-height: 375px) {
  width: 200px;
}
`;