import React from 'react';
export const ShowcastArtistIDsContext = React.createContext({
  artistIds: [],
  setArtistIds: () => {}
});

export const Provider = props => {
  const [artistIds, setArtistIds] = React.useState([]);
  return (
    <ShowcastArtistIDsContext.Provider value={{artistIds, setArtistIds}}>
      {props.children}
    </ShowcastArtistIDsContext.Provider>
  )
};


