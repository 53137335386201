// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exhibition-js": () => import("./../src/pages/exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joinus-js": () => import("./../src/pages/joinus.js" /* webpackChunkName: "component---src-pages-joinus-js" */),
  "component---src-pages-music-js": () => import("./../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-tour-js": () => import("./../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-workshop-js": () => import("./../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */),
  "component---src-templates-artist-js": () => import("./../src/templates/Artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artists-page-template-js": () => import("./../src/templates/ArtistsPageTemplate.js" /* webpackChunkName: "component---src-templates-artists-page-template-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-career-js": () => import("./../src/templates/Career.js" /* webpackChunkName: "component---src-templates-career-js" */)
}

