import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LogoImage from './LogoImage';
import Slider from '../Slider';
import Hero from '../Hero';
import HoverableLink from '../HoverableLink';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const TitleContainer = styled.div`
  position: relative;
  z-index: 1;
  top: ${props => `${props.top}px` || 'unset'};
  bottom: ${props => `${props.bottom}px` || 'unset'};
  left: ${props => `${props.left}px` || 'unset'};
  right: ${props => `${props.right}px` || 'unset'};
`;

const Title = styled.h1`
  font-size: 48px;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
`;

const SubTitle = styled.h2`
  font-size: 32px;
  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
`;

export const LinkButton = styled(HoverableLink)`
  span {
    color: white;
  }
  color: white;
  font-size: 24px;
  position: relative;
  margin: 0 auto;
  ::after {
    width: 100%;
    position: absolute;
    transition: transform .3s;
  }

  :hover::after {
    transform: scaleX(0.5);
  }
`

const LandingHero = (props) => {
  const { markdownID } = props;
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: {fileAbsolutePath: {regex: "/(home/)/"}}) {
          edges {
            node {
              frontmatter {
                  id
                  height
                  landingHeros {
                    image {
                      publicURL
                      childImageSharp{
                        fluid(maxWidth: 2000, quality: 70, cropFocus: CENTER) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    mobileImage {
                      publicURL
                      childImageSharp{
                        fluid(maxWidth: 2000, quality: 70, cropFocus: CENTER) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    title
                    subTitle
                    titlePosition {
                      top
                      bottom
                      left
                      right
                    }
                    hasLogo
                    button {
                      text
                      link
                    }
                  }
                }
            }
          }
        }
      }
    `
  );

  const renderHero = (landingHero, height, index) => {
    // const imgURL = landingHero.image.publicURL;
    const imgFluid = landingHero.image.childImageSharp.fluid;
    const mobileImgFluid = landingHero.mobileImage ? landingHero.mobileImage.childImageSharp.fluid : null;
    const { hasLogo, title, subTitle, button, titlePosition = {} } = landingHero;
    return (
      <Hero heroImage={imgFluid} mobileImage={mobileImgFluid} height={height} key={index}>
        {hasLogo && <LogoImage />}
        <TitleContainer
          {...titlePosition}
        >
          {Boolean(title) && <Title>{title}</Title>}
          {Boolean(subTitle) && <SubTitle>{subTitle}</SubTitle>}
          {Boolean(button.text) && <LinkButton text={button.text} to={button.link} />}
        </TitleContainer>
      </Hero>
    );
  };

  const selectedLandingHeroEdge = data.allMdx.edges.find(edge => {
    return edge.node.frontmatter.id === markdownID;
  });

  const heroHeight = selectedLandingHeroEdge.node.frontmatter.height || 100;

  const landingHeros = selectedLandingHeroEdge.node.frontmatter.landingHeros;

  if (landingHeros.length === 1) {
    return renderHero(landingHeros[0], heroHeight, 0);
  } else {
    return (
      <Slider bottomControl useSlide>
        {landingHeros.map((landingHero, index) => renderHero(landingHero, heroHeight, index))}
      </Slider>
    )
  }
}

LandingHero.propTypes = {
  markdownID: PropTypes.string
}

export default LandingHero;