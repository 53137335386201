import React from 'react';
import styled from 'styled-components';
import Img from "gatsby-image";
import { Colors } from '../../utils/constants';
import { centerize } from '../../utils/commonCSS';
import { whiteMask, topRightShift } from '../ImgComponents/styles';

const TopRightShiftGatsbyImg = styled(Img)`
  ${topRightShift}
`;


const UnstyledGatsbyImgRedTopRightShift = (props) => {
  const { className, ...restProps } = props;
  return (
    <div className={className}>
      <TopRightShiftGatsbyImg {...restProps} />
    </div>
  );
}
export const GatsbyImgRedTopRightShift = styled(UnstyledGatsbyImgRedTopRightShift)`
  background-color: ${Colors.EYA_RED};
  overflow: hidden;
`;


export const GatsbyImgWhiteMask = styled(Img)`
  ${whiteMask};
`;

const UnstyledGatsbyImgFlip = (props) => {
  const { className, ...restProps } = props;
  return (
    <div 
      className={className} 

    >
      <Img className="img" {...restProps}/>
      <h4 className="text">{props.text}</h4>
    </div>
  );
}

export const GatsbyImgFlip = styled(UnstyledGatsbyImgFlip)`
  position: relative;
  .text {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${centerize}
    transition: opacity .2s ease-in-out;
  }
  .img{
    transition: opacity .2s ease-in-out;
  }
  :hover {
    .text {
      opacity: 1
    }
    .img {
      opacity: 0;
    }
  }
`;
