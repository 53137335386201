import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  @media screen and (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block
    }
  }
`

const Overlay = styled.div`
  width:80%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  display:flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: white;
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  filter: brightness(0.5);
  height: ${props => `${props.height}vh` || '100vh'};
  /* & > img {
    object-fit: ${props => props.fit || 'none'};
    object-position: ${props => props.position || 'center'};
    font-family: 'object-fit: ${props => props.fit || 'fit'}; object-position: ${props => props.position || 'right'};'
  } */
`;

const Hero = (props) => (
  <Container>
    <BgImg className="desktop" fluid={props.heroImage} height={props.height} />
    <BgImg 
      className="mobile" 
      fluid={props.mobileImage ? props.mobileImage : props.heroImage} 
      height={props.height} 
    />
    <Overlay>
      {props.children}
    </Overlay>
  </Container>
);

const darkness = 0.5;
export default styled(Hero)`
  /* background: ${ props => `${`
    linear-gradient(rgba(0,0,0,${darkness}), rgba(0,0,0,${darkness})),
    url(${props.heroImage})
  `};`};
  transition: background-image 1s ease-in-out;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .img {
    overflow:hidden;
    filter: brightness(0.5);
    height: ${ props => `${props.height}vh`};
  }
  .text {
    position: absolute;
    top: 50%;
    left: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  height: ${ props => `${props.height}vh`};
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  position: relative;

  @media screen and (min-width: 1024px) {
    background-attachment: fixed;
  } */
`

