import { css } from 'styled-components';
import { Colors } from './constants';

export const centerize = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const input = css`
  border-color: ${Colors.GREY};
  border-width: 1px;
  padding: 8px 15px;
`;

export const centerizeBody = css`
  width: 66.6%;
  @media screen and (max-width: 414px) {
    width:100%;
  }
`;