import React from 'react';
import styled from 'styled-components';
import awa2021Video from '../../data/home/images/awa2021-1.mp4'
import { LinkButton } from './LandingHero';

const Container = styled.div`
  height: 100vh;
  position: relative;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
`;

const TitleContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
`;


const AnimatedHero = () => {
  return (
    <Container>
      <Video autoPlay loop muted playsInline defaultMuted>
        <source src={awa2021Video} type="video/mp4" />
      </Video>
      <TitleContainer>
        <h3>2021 A World Away Music Festival</h3>
        <LinkButton text="Learn more" to="/projects/a-world-away-music-festival-2021" />
      </TitleContainer>
    </Container>
  )
}

export default AnimatedHero;