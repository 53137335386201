import React, { useState, useEffect } from "react";
import Carousel from 'nuka-carousel';
import styled from 'styled-components'
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io';


const UnstyledDot = (props) => (
  <div className={props.className}>
    <div className="dot"></div>
  </div>
)

const Dot = styled(UnstyledDot)`
  .dot{
    border-radius: 3em;
    background-color: white;
    padding: 6px;
    margin: 16px;
    @media screen and (max-width: 1024px) {
      padding: 4px;
      margin: 4px;
    }
  }
  transform: ${props => props.active ? 'scale(.5)' : 'scale(1)'};
  transition: transform .2s;

  :hover {
    transform: scale(.5);
  }
`;


const Slider = (props) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const totalSlideCount = props.children.length;
  useEffect(() => {
    const interval = setInterval(() => {
      if(slideIndex === totalSlideCount - 1){
        setSlideIndex(0)
      } else {
        setSlideIndex(slideIndex + 1)
      }
    }, 10000);
    return () => clearInterval(interval);
  })
  const renderRightControls = ({ slideCount, currentSlide }) => (
    <div className="side-controls right revert">
      <IoIosArrowRoundForward onClick={() => {
        if (currentSlide === slideCount -1) {
          return setSlideIndex(0);
        }
        setSlideIndex(slideIndex + 1);
      }}/>
    </div>
  );

  const handleKeyDown = (index) => (event) => {
    console.log('handleKeyDown', event);
    if(event.keyCode === 13) {
      setSlideIndex(index);
    }
  }

  const renderLeftControls = ({ slideCount, currentSlide }) => (
    <div className="side-controls left revert">
      <IoIosArrowRoundBack onClick={() => {
        if (currentSlide === 0) {
          return setSlideIndex(slideCount - 1);
        }
        setSlideIndex(slideIndex - 1);
      }}/>
    </div>
  );

  const renderDots = ({slideCount, currentSlide}) => {
    return (
      <div className="bottom-controls">
        {
          Array(slideCount).fill(1).map((_, index) => (
            <div 
              role="button" 
              tabIndex={0} 
              key={index}
              onKeyDown={handleKeyDown(index)}
              onClick={() => setSlideIndex(index)}
            >
              <Dot active={index === currentSlide} />
            </div>
          ))
        }
      </div>
    )
  };

  const commonOptions = {
    transitionMode: props.useSlide ? 'scroll' : 'fade',
    speed: 500,
    heightMode: 'current',
    swiping: true,
    dragging: true,
    disableEdgeSwiping: true,
    slideIndex: slideIndex
  };
  const renderCenterControlCarousel = () => (
    <Carousel 
      {...commonOptions}
      renderCenterRightControls={renderRightControls}
      renderCenterLeftControls={renderLeftControls}
      renderBottomCenterControls={renderDots}
    >
      {props.children}
    </Carousel>
  );

  const renderButtomControlCarousel = () => (
    <Carousel 
      {...commonOptions}
      renderCenterRightControls={null}
      renderCenterLeftControls={null}
      renderBottomRightControls={renderRightControls}
      renderBottomLeftControls={renderLeftControls}
      renderBottomCenterControls={renderDots}
    >
      {props.children}
    </Carousel>
  )
  return (
    <div className={props.className}>
      {
        props.bottomControl
        ? renderButtomControlCarousel()
        : renderCenterControlCarousel()
      }
    </div>
  );
}

export default styled(Slider)`
  .slide-img {
    height: 100vh;
  }

  .side-controls {
    padding: 60px 60px 15px 60px;
    cursor: pointer;
    color: white;
    font-size: 80px;
    display: flex;
  }
  .right {
    transition: padding-right .2s;
    :hover {
      padding-right: 45px;
    }
  }
  .left {
    transition: padding-left .2s;
    :hover {
      padding-left: 45px;
    }
  }
  

  .revert {
    color: ${props => props.revert ? '#282828' : 'white'};
    .dot {
      background-color: ${props => props.revert ? '#282828' : 'white'};
    }
  }


  .slider-list {
    cursor: default !important;
  }

  .bottom-controls {
    position: relative;
    display: flex;
    padding: 30px;
  }

  @media screen and (max-width: 1024px) {
    .side-controls {
      padding: 20px;
      font-size: 40px;
      top: 100%;
      padding-bottom: 5px;
    }
    .right {
      :hover {
        padding-right: 10px;
      }
    }
    .left {
      :hover {
        padding-left: 10px;
      }
    }
    .bottom-controls {
      padding: 15px;
    }
  }
`;