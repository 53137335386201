import React from 'react';
import styled from 'styled-components';
import { centerize } from '../../utils/commonCSS';

function GalleryItem(props) {
  return (
    <div className={props.className}>
      {props.imgComponent}
      <div className="text-holder">
        <h4>{props.title}</h4>
        <p>{props.desc}</p>
      </div>
    </div>
  )
}

export default styled(GalleryItem)`
min-width: 100px;

overflow: hidden;

margin: 0;

.text-holder {
  ${centerize}
  margin-top: 20px;
  text-align: center;
}
h4 {
  color: #282828;
  line-height: 1.3em;
  margin: 0;
  font-weight: 600;
  font-size: 25px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

p {
  margin: 0;
  line-height: 28px;
  font-weight: 300;
  font-size: 12px;
  color: #666;
}

`
