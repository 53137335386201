import {
  css
} from 'styled-components';

export const whiteMask = css `
  position: relative;
  :after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    background-color: rgba(255,255,255,.2);
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-in-out;
  }
  :hover {
    :after {
      opacity: 1;
    }
  }
`;

export const topRightShift = css`
  transition: transform 0.3s;
  &:hover{
    transform: translate(30px, -30px);
  }
`;