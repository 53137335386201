import React from 'react'
import GalleryItem from './Gallery/GalleryItem';
import GalleryGrid from './Gallery/GalleryGrid';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImgRedTopRightShift } from './ImgComponents/imgComponents';

const ArtistsList = (props) => {
  const { artistsList } = props;
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(
          sort: { order: ASC, fields: [frontmatter___id] }
          filter: {fileAbsolutePath: { glob: "**/src/data/artists/*.md" }}
        ) {
          edges {
            node{
              frontmatter {
                id
                name
                image {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const hasArtistsList = artistsList && artistsList.length > 0;
  const artistsListItems = data.allMdx.edges
    .filter((edge) => {
      const { id } = edge.node.frontmatter;
      const isInArtistsList = hasArtistsList && artistsList.includes(id);
      const needShowArtists = !hasArtistsList || isInArtistsList;
      return needShowArtists;
    })
    .map((edge) => {
      const { node } = edge;
      const { name, image, id } = node.frontmatter;
      return (
        <Link to={`/artists/${id}`} key={id}>
          <GalleryItem
            imgComponent={<GatsbyImgRedTopRightShift
              fluid={image.childImageSharp.fluid}
            />}
            title={name}
          />
        </Link>
      );
    });

  return (
    <GalleryGrid>
      {artistsListItems}
    </GalleryGrid>
  );
}
ArtistsList.propTypes = {
  artistsList: PropTypes.arrayOf(PropTypes.string)
}

export default ArtistsList;