import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { centerize } from '../../utils/commonCSS';


export const TextArea = styled.div`
  min-width: 33.3%;
  ${centerize}
  padding: 48px;
  text-align: center;
  .desc {
    width: 80%;
  }
  p {
    margin: 0;
  }
`;

const GridImageItemWrapper = styled(Link)`
.grid-image-item {
  position: relative;
  height: 100%;
  width: 100%;
  color: white;

  img {
    /* position: absolute; */
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(60%);
    transition: 0.3s;
  }

  .text-area {
    position: absolute;
    ${centerize}
    text-align: center;

    h2 {
      margin-bottom: 4px;
    }
  }

  ${centerize}

  :hover {
    img {
      filter: brightness(85%);
    }
  }
  word-break: break-word;
}
`;



const GridImageItem = ({
  imgURL,
  title,
  desc,
  projectId,
}) => {
  return (
    <GridImageItemWrapper to={`/projects/${projectId}`}>
      <div className='grid-image-item'>
          <img alt={title} src={imgURL}/>
          <div className="text-area">
            <h2>
              {title}
            </h2>
            <span>
              {desc}
            </span>
          </div>
      </div>
    </GridImageItemWrapper>
  );
};

const ImageGrid = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-areas: "a b b"
                       "a c d";

  grid-template-rows: repeat(2, 350px);
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 768px) {
    grid-template-areas: "a"
                         "b"
                         "c"
                         "d";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  .a {
    grid-area: a;
  }
  .b {
    grid-area: b;
  }
  .c {
    grid-area: c;
  }
  .d {
    grid-area: d;
  }
`


const ProjectShowcase = ({className}) => {
  const data = useStaticQuery(
    graphql`
      query {
        mdx(frontmatter: {id: {eq: "project-showcase"}}) {
          frontmatter {
            projects {
              id
              image {
                publicURL
              }
              title
              desc
            }
          }
        }
      }
    `
  );
  const selectedProjects = data.mdx.frontmatter.projects;
  const selectedProjectsGridItems = selectedProjects
  .filter((_, index) => index < 4) // we only show 4 items
  .map((project) => {
    const imgURL = project.image.publicURL;
    return (
      <GridImageItem 
        key={project.id}
        imgURL={imgURL}
        title={project.title}
        desc={project.desc}
        projectId={project.id}
      />);
  });

  return (
    <div className={className}>
      <TextArea>
          <h1>Projects</h1>
          <p className="desc">Be our witness, <br/> EYA production since 2015</p>
          {/* <HoverableLink to='/projects' text="Learn more projects"/> */}
      </TextArea>

      <ImageGrid>
        <div className='a'>
          {selectedProjectsGridItems[0]}
        </div>
        <div className='b'>
          {selectedProjectsGridItems[1]}
        </div>
        <div className='c'>
          {selectedProjectsGridItems[2]}
        </div>
        <div className='d'>
          {selectedProjectsGridItems[3]}
        </div>
      </ImageGrid>
    </div>
  );
}

export default styled(ProjectShowcase)`
display: flex;

@media screen and (max-width: 768px) {
  flex-direction: column;
}
`;